import React, { useEffect, useState } from 'react';
import { Link, graphql } from 'gatsby';

export const SearchPage = ({
    data: {
        allCraftEntryInterface: { nodes },
    },
}) => {
    let results = [];
    const [searchQuery, updateQuery] = useState('');

    if (typeof window !== 'undefined') {
        const { search } = window.location;
        const query = new URLSearchParams(search).get('s');
        useEffect(() => {
            updateQuery(query);
        }, [query]);

        const pages = nodes;

        if (searchQuery.length > 2) {
            const lowercaseQuery = searchQuery.toLowerCase();
            const filterTitle = pages.filter(node =>
                node.title.toLowerCase().includes(lowercaseQuery)
            );
            const plainTextFilter = pages.filter(page =>
                page.plainText?.toLowerCase().includes(lowercaseQuery)
            );
            const richTextFilter = pages.filter(page =>
                page.richTextStandard?.toLowerCase().includes(lowercaseQuery)
            );

            const projEvMatrixRichText = pages.filter(page => {
                let matrixString = JSON.stringify(page.contentMatrix);
                matrixString = matrixString?.toLowerCase();
                return matrixString?.includes(lowercaseQuery);
            });

            results = [
                ...filterTitle,
                ...plainTextFilter,
                ...richTextFilter,
                ...projEvMatrixRichText,
            ];
            results = results.filter(
                (page, index, self) => index === self.findIndex(t => t.remoteId === page.remoteId)
            );
        }
    }

    return (
        <div className="spacing--small rich-text pt-24px lg:pt-46">
            <h1 style={{ marginBottom: 48 }}>Search Results ({results.length})</h1>
            {results.length > 0 ? (
                <ul>
                    {results.map((page, i) => {
                        let section = 'Page';
                        if (page.sectionHandle === 'projectsAndEvents')
                            section = 'Projects and Events';
                        if (page.sectionHandle === 'shop') section = 'Shop';
                        return (
                            <li key={`${page.title + i}`} style={{ marginBottom: 12 }}>
                                <Link to={`/${page.uri}`} style={{ textDecoration: 'none' }}>
                                    <h6 style={{ marginBottom: 2 }}>{page.title}</h6>
                                    <p>{section}</p>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            ) : null}
            {searchQuery && searchQuery.length <= 2 ? (
                <p>Please use three or more characters in search</p>
            ) : null}
        </div>
    );
};

export const pageQuery = graphql`
    query {
        allCraftEntryInterface(sort: { fields: dateCreated, order: DESC }) {
            nodes {
                remoteId
                uri
                title
                sectionHandle
                ... on Craft_projectsAndEvents_default_Entry {
                    id
                    plainText
                    richTextStandard
                    contentMatrix {
                        ... on Craft_contentMatrix_richTextBlock_BlockType {
                            id
                            initialText
                            richText
                        }
                        ... on Craft_contentMatrix_textAndImageBlock_BlockType {
                            blockCopy
                        }
                    }
                }
                ... on Craft_shop_product_Entry {
                    id
                    plainText
                    richTextStandard
                }
                ... on Craft_pages_default_Entry {
                    id
                    contentMatrix {
                        ... on Craft_contentMatrix_linkBlocks_BlockType {
                            linkItem {
                                ... on Craft_linkItem_BlockType {
                                    richText
                                }
                            }
                        }
                        ... on Craft_contentMatrix_richTextBlock_BlockType {
                            id
                            initialText
                            richText
                        }
                        ... on Craft_contentMatrix_textAndImageBlock_BlockType {
                            blockCopy
                        }
                    }
                }
            }
        }
    }
`;

export default SearchPage;
